<template>
  <div class="register-index">
    <div class="register-body">
      <div class="register-box">
        <div class="title">欢迎登录老兵后台管理系统</div>
        <el-form ref="loginForm" :model="loginForm" :rules="rules">
          <el-form-item prop="account">
            <el-input
              v-model="loginForm.account"
              prefix-icon="el-icon-s-custom"
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
            />
          </el-form-item>
          <el-form-item style="text-align: left">
            <el-checkbox v-model="loginForm.checked" label="0"
              >记住用户名</el-checkbox
            >
          </el-form-item>
          <el-form-item>
            <el-button
              style="width: 100%"
              type="primary"
              @click="login('loginForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="register-footer">
      <div>
        <img
          src="@/assets/images/ftlogo.png"
          style="width: 50px; height: 50px"
        />
      </div>
      <div style="font-size: 16px; color: #666666">
        <span>Copyright©2007 ～ 2019 Okura Co.,Ltd All right reserved.</span>
        <br />
        <span
          >技术支持：<a
            href="https://www.iif2f.com"
            target="blank"
            style="color: #126eff"
            >全购网络有限公司</a
          ></span
        >
      </div>
    </div>
    <el-dialog
      :visible.sync="selectVisible"
      title="请选择要登录的单位"
      width="1000px"
    >
      <div class="company-list-box">
        <div
          v-for="item in companyList"
          :key="item.id"
          class="company-box"
          :class="currentSelectId == item.id ? 'active' : ''"
          @click="itemCompany(item)"
        >
          <img src="@/assets/images/company.png" alt="" />
          <span>{{ item.enterName }}</span>
        </div>
      </div>
      <el-divider />
      <div slot="footer" class="footer-dialog">
        <el-button type="primary" @click="selected">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { webLogin, chooseCompany } from "@/utils/api.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    // const checkAccount = (rule, value, callback) => {
    //   const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
    //   if (value === "") {
    //     callback(new Error("请输入账号"));
    //   } else if (!phoneReg.test(value)) {
    //     callback("请输入正确的手机号");
    //   }
    // };
    return {
      loginForm: {
        account: "",
        password: "",
        checked: false, // 是否记住密码  默认不记住
      },
      selectVisible: false,
      companyList: [],
      currentSelectId: "",
      isLegal: false,
      rules: {
        account: {
          required: true,
          message: "请输入账号",
          trigger: "blur",
        },
        password: {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      nickname: (state) => state.nickname,
      userId: (state) => state.userId,
      companyId: (state) => state.companyId,
    }),
  },
  mounted() {
    window.addEventListener("keyup", this.keyUp);
    this.getCookie();
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyUp, false);
  },
  methods: {
    ...mapMutations({
      saveInfo: "saveInfo",
      saveCompanyId: "saveCompanyId",
    }),
    login(formName) {
      // 判断复选框是否被勾选 勾选则调用配置cookie方法
      if (this.loginForm.checked == true) {
        // 传入账号名，密码，门店，和保存天数3个参数
        this.setCookie(
          this.loginForm.account,
          this.loginForm.password,
          this.loginForm.checked,
          7
        );
      } else {
        // 清空Cookie
        this.clearCookie();
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sessionStorage.account = this.loginForm.account;
          this.$http({
            url: this.$http.api("/webLogin"),
            data: this.$http.adornData(this.loginForm, false),
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              // 有公司
              this.saveInfo(res.data);
              const { selectCompany } = res.data;
              if (selectCompany) {
                // 有很多公司
                this.companyList = res.data.companyList;
                this.selectVisible = true;
              } else {
                // 只有一个公司
                this.$router.push({
                  path: "/index",
                });
              }
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 设置cookie
    setCookie(l_name, l_pwd, l_checked, exdays) {
      var exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie = // 账户名
        "userName" + "=" + l_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = // 密码
        "userPwd" + "=" + l_pwd + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = // 是否勾选
        "checked" + "=" + l_checked + ";path=/;expires=" + exdate.toGMTString();
    },
    // 读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); // 再次切割
          // 判断查找相对应的值
          if (arr2[0] == "userName") {
            this.loginForm.account = arr2[1]; // 保存到保存数据的地方
          } else if (arr2[0] == "userPwd") {
            this.loginForm.password = arr2[1];
          } else if (arr2[0] == "checked") {
            this.loginForm.checked = true;
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie("", "", false, -1); // 修改2值都为空，天数为负1天就好了
    },

    // 按下回车触发登录
    keyUp(e) {
      const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (e.keyCode === 13) {
        if (!this.loginForm.account) {
          this.$message.error("请输入账号");
          return;
        } else if (!phoneReg.test(this.loginForm.account)) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        if (!this.loginForm.password) {
          this.$message.error("请输入密码");
          return;
        }
        this.login();
      }
    },
    itemCompany(item) {
      this.isLegal = !!item.isLegal;
      this.currentSelectId = item.id;
    },
    // 选中公司确认
    async selected() {
      this.saveCompanyId(this.currentSelectId);
      const form = {
        account: sessionStorage.account,
        auth: this.auth,
        companyId: this.companyId,
        isLegal: true,
        nickname: this.nickname,
        userId: this.userId,
      };
      const res = await chooseCompany(form);
      if (res.code === 200) {
        this.$router.push({
          path: "/index",
        });
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.register-index {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/loginbgc.png);
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .register-body {
    width: 500px;
    height: 430px;
    background: #ffffff;
    border-radius: 6px;
    padding: 70px;
    .title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 30px;
    }
  }
  .register-footer {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 0 63px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    background: #ffffff;
  }
  .company-list-box {
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    .company-box {
      width: 276px;
      height: 81px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: #ffffff;
      border: 2px solid #c8c8c8;
      margin: 0 12px 20px 0;
      img {
        margin: 0 13px 0 26px;
      }
    }
    .active {
      background-color: #f9fcff;
      border: 2px solid #126eff;
    }
  }
  .footer-dialog {
    text-align: center;
  }
}
</style>
